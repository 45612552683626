import React, {useState} from "react";
import {graphql, Link} from "gatsby";
import {Helmet} from "react-helmet";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import HeaderLight from "../../components/header/headerLight/headerLight";

import "./style.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import biFilter from "../../images/produit/bi_filter.svg"
import FiltresOffres from "../../components/filtresOffres/filtresOffres";
import {StaticImage} from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";


const Offres = ({location, data}) => {
    const metiers = data.allStrapiMetier.nodes;
    const types = data.allStrapiOffre.nodes[0].Types;
    const produits = data.allStrapiProduit.nodes;


    // On ajoute breez
    if (produits.filter(p => p.Titre.toLowerCase().includes("breez")).length === 0) {
        const breez = {
            Slug: "breez",
            Titre: "Breez, l'assurance des engins zéro émission",
            Tri: 10,
            Description: "Breez par L'Auxiliaire est l’offre d'assurance dédiée aux engins et matériels zéro émission qui valorise votre engagement. Avec breez, accélérez votre transition !",
            Type_Produit: {Nom: "Véhicules et matériels"}
        }
        produits.push(breez);
    }

    metiers.forEach((metier) => {
        const produit = metier.produitSlug = produits.find(
            (p) => p.strapi_id === metier.Produit.strapi_id
        );
        if (produit) {
            metier.produitSlug = produit.Slug
        }
    });
    const metiersFiltered = metiers.filter(metier => metier.Produits.length !== 0)

    // Filtres
    let produitsFiltered
    const [filterTypesDeProduit, setFilterTypesDeProduit] = useState([]);
    const [filterMetier, setFilterMetier] = useState(null);

    const [showMobileFilter, setShowMobileFilter] = useState(false);


    // filtre des produits par types de produit
    if (filterTypesDeProduit.length === 0)
        produitsFiltered = produits
    else
        produitsFiltered = produits.filter(produit => {
            if (produit.Type_Produit === null) return false
            const index = filterTypesDeProduit.indexOf(produit.Type_Produit.Nom)
            return index !== -1
        })

    // filtre des produits par metier
    if (filterMetier !== null) {
        const produitSlugs = filterMetier.Produits.map(produit => produit.Slug)
        produitsFiltered = produitsFiltered.filter(produit => {
            if (filterMetier.Produits === undefined) return false
            const index = produitSlugs.indexOf(produit.Slug)
            return index !== -1
        })
    }

    /**
     * ajoute ou supprimer le type de produit au filtre et change l'input
     */
    function toggleFilterProduit(typeProduit) {
        const checkbox = document.getElementById(typeProduit)
        checkbox.checked = !checkbox.checked

        if (checkbox.checked === true) {
            // ajoute le type de produit au filtre
            setFilterTypesDeProduit([...filterTypesDeProduit, typeProduit])
        } else {
            // retire le type de produit du filtre
            const array = filterTypesDeProduit.filter(t => t !== typeProduit)
            setFilterTypesDeProduit(array)
        }
    }

    function resetFilterMetier() {
        const elems = document.getElementsByClassName("offres__job__filters")
        for (const elem of elems) {
            elem.classList.remove("hide-child")
        }

        const selectedJobs = document.getElementsByClassName("offres__job__show")
        for (const job of selectedJobs) {
            job.classList.remove("offres__job__show")
        }
        setFilterMetier(null)
    }

    return (
        <>
            <Helmet>
                <body className="offres"/>
            </Helmet>
            <Layout>
                <SEO title="Assurances"
                     description="L'ensemble de nos contrats en assurance à destination des professionnels du bâtiment des travaux publics : décennale, responsabilité civile, police de chantier, santé, mutuelle…"/>
                <HeaderLight
                    title={
                        <>
                            Trouvez <strong>l'assurance dont vous avez besoin</strong>.<br></br> Rien
                            de plus, rien de moins.
                        </>
                    }
                    location={location}
                    crumbLabel="Nos assurances"
                />

                <FiltresOffres metiers={metiersFiltered} setFilterMetier={setFilterMetier} filterMetier={filterMetier}
                               resetFilterMetier={resetFilterMetier} types={types}
                               toggleFilterProduit={toggleFilterProduit} setShowMobileFilter={setShowMobileFilter}
                               showMobileFilter={showMobileFilter}/>

                <section className="offres__content">

                    <input className="offres__mobile__button" id="filter_button" type="checkbox"
                           onChange={() => setShowMobileFilter(true)}/>

                    <label htmlFor="filter_button" className="offres__mobile__label">
                        <img src={biFilter} alt="filtres mobile button"/>
                        Filtrer
                    </label>
                    <section className="offres__filters">

                        <div className="offres__job">
                            <h3>Je suis...</h3>
                            <div className="offres__job__filters">
                                {metiersFiltered.map((metier, i) => {
                                    return (
                                        <span
                                            key={i}
                                            id={"metier-" + metier.id}
                                            onClick={() => {
                                                if (filterMetier !== null) {
                                                    resetFilterMetier()
                                                    return
                                                }

                                                const elems = document.getElementsByClassName("offres__job__filters")
                                                for (const elem of elems) {
                                                    elem.classList.add("hide-child")
                                                }

                                                const selectedJob = document.getElementById("metier-" + metier.id)

                                                selectedJob.classList.add("offres__job__show")

                                                setFilterMetier(metier)
                                            }}
                                        >
                                            {metier.Titre}
                                        </span>
                                    );
                                })}
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className={"offres__job__arrow"}
                                    onClick={resetFilterMetier}
                                />
                            </div>
                        </div>
                        <div className="offres__type_produit">
                            <h3>Je souhaite assurer...</h3>
                            <div>
                                {types.map((type, t) => {
                                    return (
                                        <React.Fragment key={t}>
                                            <p className="offres__type_produit-title">{type.Nom}</p>
                                            {type.Type_Produits.map((type_produit, tp) => {
                                                return (
                                                    <div
                                                        key={tp}
                                                        className="offres__type_produit-name"
                                                        onClick={() => toggleFilterProduit(type_produit.Nom)}
                                                    >
                                                        <input type="checkbox"
                                                            readOnly
                                                            aria-label={`Filtrer selon ${type_produit.Nom}`}
                                                            checked={filterTypesDeProduit.find((typeDeProduit) => typeDeProduit === type_produit.Nom) !== undefined}/>
                                                        <p>{type_produit.Nom}</p>
                                                    </div>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        </div>
                    </section>

                    <section className="offres__produits">
                        {
                            produitsFiltered?.sort((a, b) => {
                                const sortResult = (a?.Tri ?? a?.id) - (b?.Tri ?? b?.id);

                                if (sortResult === 0) {
                                    return a?.Titre[0]?.localeCompare(b?.Titre[0]);
                                } else {
                                    return sortResult;
                                }
                            })?.map((produit, i) => {
                                const url = produit.Slug.toLowerCase().includes("breez") ? "/breez" : "/produit/" + produit.Slug;
                                return (
                                    <div key={i} className="offres__produit">
                                        <Link to={url}>
                                            {produit.Titre.toLowerCase().includes("breez") ? (
                                                <div className={"offres__produit__content"} style={{color: 'white'}}>
                                                    <StaticImage
                                                        className={"offres__produit__image"}
                                                        src={"../../images/breez/breez_page_nos_offres.webp"}
                                                        alt={"Image " + produit.Titre}
                                                    />
                                                    <h3 style={{zIndex: 1}}>{produit.Titre}</h3>
                                                    <p style={{zIndex: 1}}>{produit.Description}</p>
                                                </div>
                                            ) : (
                                                <BackgroundImage
                                                    Tag="div"
                                                    className="offres__produit__content"
                                                    fluid={produit.Image_De_Couverture.localFile.childImageSharp.fluid}
                                                    preserveStackingContext
                                                    style={{
                                                        color: produit.Image_De_Couverture ? 'white' : '',
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <h3>{produit.Titre}</h3>
                                                    <p>{produit.Description}</p>
                                                </BackgroundImage>
                                            )}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </section>
                </section>
            </Layout>
        </>
    );
};

export const query = graphql`
  query OffresPageQuery {
    allStrapiOffre {
      nodes {
        Types {
          Nom
          Type_Produits {
            Nom
          }
        }
      }
    }

    allStrapiProduit(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        id
        strapi_id
        Titre
        Description
        Slug
        Type_Produit {
          Nom
        }
        Slug
        Tri
        Image_De_Couverture {
          localFile {
            childImageSharp {
              fluid(
                quality: 90,
                maxWidth: 600
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    
    allStrapiMetier {
      nodes {
        id
        Titre
        Image_De_Couverture {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        Produit {
          strapi_id
          Slug
          Titre
        }
        Produits {
          Slug
          Titre
        }
      }
    }
  }
`;

export default Offres;
