import { StaticImage } from "gatsby-plugin-image";
import React from "react";


import "./filtresOffres.scss";

export default function FiltresOffres({metiers, setFilterMetier,filterMetier, types, toggleFilterProduit, resetFilterMetier, setShowMobileFilter, showMobileFilter}) {
  return (
    <section className={` ${showMobileFilter ? '' : 'hide-mobile-filters'} filtres-offres`}>

        <div className="filtres-offres__close">
          <StaticImage
            src="../../images/menu/menu-hamburger.png"
            alt="img menu"
            placeholder="blurred"
            layout="fixed"
            width={38}
            height={38}
            onClick={() => setShowMobileFilter(false)}
          />
        </div>
        <div className="offres__job">
            <h2>Je suis...</h2>
            <div className="offres__job__filters">
              {metiers.map((metier, i) => {
                return (
                  <span
                    key={i}
                    id={"metier-"+metier.id+"-mobile"}
                    onClick={() => {
                      if(filterMetier !== null) {
                        resetFilterMetier()
                        return
                      }

                      const elems = document.getElementsByClassName("offres__job__filters")
                      for (const elem of elems) {
                        elem.classList.add("hide-child")
                      }

                      const selectedJobMobile = document.getElementById("metier-"+metier.id+"-mobile")

                      selectedJobMobile.classList.add("offres__job__show")
                      
                      setFilterMetier(metier)
                    }}
                  >
                    {metier.Titre}
                  </span>
                );
              })}
            </div>
        </div>

        <div className="offres__type_produit">
            <h2>Je souhaite assurer...</h2>
            {types.map((type, t) => {
              return (
                <React.Fragment key={t}>
                  <p className="offres__type_produit-title" >{type.Nom}</p>
                  {type.Type_Produits.map((type_produit, tp) => {
                    return (
                      <div
                        key={tp}
                        className="offres__type_produit-name"
                        onClick={() => toggleFilterProduit(type_produit.Nom)}
                      >
                        <input type="checkbox" id={type_produit.Nom} onClick={() => toggleFilterProduit(type_produit.Nom)} />
                        <p>{type_produit.Nom}</p>
                      </div>
                    )
                  })}
                </React.Fragment>
              )
            })}
        </div>
    </section>
  );
}
